.payment-flow-page-sec{
  background-color:var(--page-background-color);
  width: 100%;
  min-height: 100vh;
}

.payment-main-wrapped {
  /* background-image: url("/public/img/pay-in-bg.png"); */
  /* background-color: var(--primary-color); */
  /* width: 100%;
  min-height: 100vh; */
  /* background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */
  display: flex;
  align-items: center;
  width: 100%;
  min-height:calc(100vh - 100px);
}

.payment-flow-header-sec{
  padding: 1em 0;
}

.efi-payment-left-wrapped h1 {
  font-size: 2.5em;
  line-height: 1.5;
  font-weight: 700;
  color: var(--text-primary-color);
  margin-top: 1em;
}

.efi-payment-left-wrapped h1 span {
  font-weight: 400;
}

.efi-payment-right-wrapped {
  background-color: var(--secondary-color);
  /* padding: 2em; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 12px;
  overflow: hidden;
  /* background-image: url('/public/img/from-bg.png'); */
}

.efi-form-wrap-top {
  padding: 2em;
  /* background-image: url('/public/img/from-bg.png');
  background-repeat: no-repeat;
  background-position: center 78%;
  background-size: cover; */
  background-color:var(--card-top);
}

.efi-form-wrap-top .form-heading {
  margin-bottom: 1.5em;
  text-align: center;
}

.efi-total-amount-frame {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 1em;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 1em;
  border-radius: 8px;
}

.efi-pay-method-info {
  padding-bottom: 1em;
}

.efi-pay-method-info:last-child {
  padding-bottom: 0em;
}

.efi-form-wrap-top .form-heading h2 {
  font-size: 1.2em;
  font-weight: 700;
  color: var(--text-primary-color);
  margin-bottom: 0;
}

.efi-total-amount-frame p {
  color: var(--text-primary-color);
  margin-bottom: 0em;
  font-size: 0.9em;
  font-weight: 400;
}

.efi-total-amount-frame h3 {
  font-size: 1.4em;
  font-weight: 700;
  color: var(--text-primary-color);
  margin-bottom: 0;
  text-align: right;
}

.efi-total-amount-frame h3 span {
  font-size: 1em;
  color: var(--text-secondary-color);
}

.total-amount-crypt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
/* 
.efi-form-wrap-bottom {
  background: #FFFAF6;
} */

.efi-form-wrap-middle {
  padding: 2em 2em;
  padding-bottom: 0.5em;
}

.middle-wrap {
  background-color: var(--card-middle);
}

.efi-pay-method-info p {
  color: var(--text-secondary-color);
  font-size: 0.9em;
  margin-bottom: 0.5em;
  font-weight: 400;
}

.efi-pay-method-info h4 {
  font-size: 0.9em;
  font-weight: 600;
  color: var(--text-primary-color);
  margin-bottom: 0;
}

.css-1b9cx9-control {
  border: none !important;
}

.efi-pay-method-info .total-amount-crypt {
  justify-content: flex-start !important;
}

.efi-form-wrap-bottom .form-label {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--text-primary-color);
}

.efi-form-wrap-bottom .form-control::placeholder{
  font-size: 0.9em;
}

.efi-form-wrap-bottom .input-group-text {
  background-color: transparent!important;
  border-top-right-radius: 0px!important;
  border-bottom-right-radius: 0px!important;
  border-radius: 8px;
}

.form-group-frame .form-control {
  border-left: 0 !important;
  border-radius: 8px;
  height: 40px;
}

.efi-form-wrap-bottom .form-control:focus {
  border-color: #57637245 !important;
  box-shadow: unset !important;
}

.form-wrap-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-cancel {
  background: var(--btn-secondary-color) !important;
  padding: .8em 2em !important;
  color: var(--text-primary-color) !important;
  border: none !important;
  width: 100%;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border-radius: unset !important;
}

.btn-cancel:hover,
.btn-cancel:focus,
.btn-cancel:active{
  text-decoration: none !important;
  color: var(--text-primary-color)!important;
  background-color: var(--btn-secondary-color-hover) !important;
  background-position: 100% 0;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-next {
  background: var(--btn-primary-color) !important;
  padding: .8em 2em !important;
  color: var(--text-primary-color) !important;
  border: none !important;
  width: 100%;
  font-size: 0.9em !important;
  font-weight: 600 !important;
  border-radius: 0 !important;
  -webkit-transition: .5s;
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
  background-size: 300% 100%;
}

.btn-next:hover,
.btn-next:focus,
.btn-next:active{
  /* background-position: 100% 0; */
  background: var(--btn-primary-color-hover) !important;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.form-group-frame {
  padding: 1em 2em;
}

.form-control::placeholder {
  color: var(--text-secondary-color);
  font-size: 1em;
  font-weight: 600;
}

.upi-id-main-wrap {
  background-color: var(--secondary-color);
  padding: 1.5em;
  border-radius: 8px;
}

.upi-id-main-wrap span {
  color: var(--text-tertiary-color);
}

.upi-id-info {
  background-color: var(--secondary-color);
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: space-between;
}

.upi-id-sec {
  background: #F7FBFF;
  padding: 0.5em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* word-break: break-all; */
  width: 100%;
  border-radius: 8px;
  color: var(--text-primary-color);
}

.bank-account-info {
  width: 100%;
  border-radius: 8px;
  color: var(--text-primary-color);
  padding: 0.5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5em;
}

.bank-account-info div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bank-account-info p {
  margin-bottom: 0;
  color: var(--text-secondary-color);
}

.bank-account-info span{
  color : var(--text-primary-color);
}

.upi-id-copy-pad button {
  background-color: #F7FBFF !important;
  border-radius: 8px !important;
  display: flex !important;
  align-items: center;
  font-size: 1em !important;
  gap: 0.5em;
  border: 0 !important;
  color: var(--text-tertiary-color) !important;
}

.upi-id-main-wrap span {
  /* color: var(--text-secondary-color); */
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: .5em;
  display: inline-block;
}

.qr-code-main-wrapped {
  margin-top: .5em;
  background-color: var(--secondary-color);
  padding: 1.5em;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
}

.session-icon {
  width: 6em;
  height: 6em;
  object-fit: contain;
  margin-bottom: 1em;
}

.qr-bar {
  width: 6em;
  height: 6em;
  object-fit: contain;
  margin: auto;
}

.qr-code-main-wrapped p {
  color: var(--text-secondary-color);
  font-size: .88em;
  line-height: 1.8;
  font-weight: 500;
}

.amount-pay-sec {
  font-size: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-top: 1em;
}

.amount-pay-sec span {
  color: var(--text-secondary-color);
}

.efi-pay-method-info h3 {
  font-size: 1.3em;
  font-weight: 700;
  color: var(--text-primary-color);
}

.utr-file-upload {
  opacity: 0;
  position: absolute;
}

.utr-file-overlay {
  color: var(--text-tertiary-color);
  border: 1px dashed var(--text-tertiary-color);
  background: var(--background-tertiary-color);
  padding: 0.5em;
  border-radius: 8px;
  min-height: 12em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.utr-file-overlay span {
  font-size: 1.1em;
  font-weight: 600;
  text-decoration: underline;
  position: relative;
}

.utr-file-overlay p {
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.efi-file-sec {
  padding: 1em 2em;
}

.efi-file-sec .form-label {
  font-size: 0.9em;
  font-weight: 500;
  color: var(--text-primary-color);
}

.efi-file-sec .form-control,
.card-details-form .form-control {
  border-radius: 8px !important;
  height: 40px;
}

.efi-file-sec .form-control::placeholder {
  font-size: 0.9em;
}

.card-details-form .form-control::placeholder{
  font-size: 0.9em;
}

.card-details-form {
  padding: 1em 2em;
}

.form-heading svg {
  background-color: #ffffff7a;
  /* padding: 0.5em; */
  float: left;
  width: 2em;
  height: 2em;
  /* display: inline-block; */
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
}

.card-details-form p {
  font-size: 1em;
  color: var(--text-secondary-color);
  font-weight: 500;
}

.payment-success-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 399px;
  gap: 2em;
}

.payment-success-wrap p {
  color: var(--text-primary-color);
  font-size: 1em;
  font-weight: 500;
}

.succes-icon {
  width: 7em;
  height: 7em;
  object-fit: contain;
}

.session-sec-wraped {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3em;
}

.session-sec-wraped h4 {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--text-primary-color);
  margin-bottom: 1em;
  margin-bottom: 0.5em;
  text-transform: capitalize;
}

.session-sec-wraped p {
  font-size: 1em;
  margin-bottom: 1em;
  line-height: 1.8em;
  font-weight: 400;
  color: var(--text-secondary-color);
}

.cancel-pay {
  background-color: var(--btn-cancel-confirm-color) !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 0.6em 2em !important;
  font-size: .98em !important;
  font-weight: 600 !important;
  color: var(--text-cancel-color) !important;
}

.cancel-pay:hover {
  background-color: var(--btn-cancel-confirm-color-hover) !important;
}

.no-pay {
  background-color: unset !important;
  color: var(--text-primary-color) !important;
  border: none !important;
  text-decoration: underline !important;
  font-weight: 700 !important;
}

.pay-cancel-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.payment-cancel-wrapped {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-cancel-wrapped h3 {
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  color: var(--text-primary-color);
}

.payment-cancel-wrapped p {
  color: var(--text-secondary-color);
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8;
  text-align: center;
}

.payment-cancel-wrapped img {
  width: 7em;
  height: 7em;
  object-fit: contain;
  margin-bottom: 1em;
}

.modal-header {
  border-bottom: unset !important;
}

.screenshot {
  /* width: 100%;
  height: 10em;
  margin-top: 1em; */
  object-fit: contain;
  border: 1px dashed var(--text-tertiary-color);
  background: var(--background-tertiary-color);
  padding: 0.5em;
  border-radius: 8px;
  position: relative;
}

.screenshot img {
  width: 100%;
  height: 10em;
  object-fit: contain;
  /* margin-top: 1em;
  border: 1px dashed var(--text-tertiary-color);
  background: var(--background-tertiary-color);
  padding: 0.5em;
  border-radius: 8px; */
}

.screenshot button {
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: 1px solid var(--text-tertiary-color);
}

.service-fee {
  text-align: right;
  font-size: 0.9em;
  color: var(--text-secondary-color);
}

.modal-content {
  background: var(--card-middle) !important;
}

.payment-icon {
  width: 3em;
}

.payment-method-sec {
  font-size: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  margin-top: 1em;
}

@media (min-width: 991.98px) {
  .payment-method-sec {
    display: none !important;
  }
}